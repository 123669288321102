body {
  margin: 0;
  background-color: #394441;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 1000'%3E%3Cg %3E%3Ccircle fill='%23394441' cx='50' cy='0' r='50'/%3E%3Cg fill='%2337493f' %3E%3Ccircle cx='0' cy='50' r='50'/%3E%3Ccircle cx='100' cy='50' r='50'/%3E%3C/g%3E%3Ccircle fill='%23364d3d' cx='50' cy='100' r='50'/%3E%3Cg fill='%2334523b' %3E%3Ccircle cx='0' cy='150' r='50'/%3E%3Ccircle cx='100' cy='150' r='50'/%3E%3C/g%3E%3Ccircle fill='%23335639' cx='50' cy='200' r='50'/%3E%3Cg fill='%23315b37' %3E%3Ccircle cx='0' cy='250' r='50'/%3E%3Ccircle cx='100' cy='250' r='50'/%3E%3C/g%3E%3Ccircle fill='%232f5f35' cx='50' cy='300' r='50'/%3E%3Cg fill='%232e6433' %3E%3Ccircle cx='0' cy='350' r='50'/%3E%3Ccircle cx='100' cy='350' r='50'/%3E%3C/g%3E%3Ccircle fill='%232c6831' cx='50' cy='400' r='50'/%3E%3Cg fill='%232b6d2f' %3E%3Ccircle cx='0' cy='450' r='50'/%3E%3Ccircle cx='100' cy='450' r='50'/%3E%3C/g%3E%3Ccircle fill='%2329712d' cx='50' cy='500' r='50'/%3E%3Cg fill='%2327762b' %3E%3Ccircle cx='0' cy='550' r='50'/%3E%3Ccircle cx='100' cy='550' r='50'/%3E%3C/g%3E%3Ccircle fill='%23267a29' cx='50' cy='600' r='50'/%3E%3Cg fill='%23247f27' %3E%3Ccircle cx='0' cy='650' r='50'/%3E%3Ccircle cx='100' cy='650' r='50'/%3E%3C/g%3E%3Ccircle fill='%23238325' cx='50' cy='700' r='50'/%3E%3Cg fill='%23218823' %3E%3Ccircle cx='0' cy='750' r='50'/%3E%3Ccircle cx='100' cy='750' r='50'/%3E%3C/g%3E%3Ccircle fill='%231f8c21' cx='50' cy='800' r='50'/%3E%3Cg fill='%231e911f' %3E%3Ccircle cx='0' cy='850' r='50'/%3E%3Ccircle cx='100' cy='850' r='50'/%3E%3C/g%3E%3Ccircle fill='%231c951d' cx='50' cy='900' r='50'/%3E%3Cg fill='%231b9a1b' %3E%3Ccircle cx='0' cy='950' r='50'/%3E%3Ccircle cx='100' cy='950' r='50'/%3E%3C/g%3E%3Ccircle fill='%23199E19' cx='50' cy='1000' r='50'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: contain;
  overflow-y: scroll;
  /* //Background image is generated on https://www.svgbackgrounds.com/ */
}

#heroPage {
  padding-bottom: 180px;
}

.bigSvgIcon {
  width: 50%;
  height: 50%;
}

.dragon {
  transform: scale(0.4);
}

.hidden {
  display: none;
}

.center {
  display: flex;
  justify-content: center;
}

.custom-shape-divider-bottom-1630487986 {
  position: relative;
  bottom: -4px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: -2;
}

.custom-shape-divider-bottom-1630487986 svg {
  position: relative;
  display: block;
  width: calc(193% + 1.3px);
  height: 295px;
  transform: rotateY(180deg);
}

.shape-fill {
  fill: #ffffff;
}
.custom-shape-divider-bottom-1632390290 {
  position: absolute;
  bottom: -70px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1632390290 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 73px;
}
.custom-shape-divider-top-1633940255 {
  position: relative;
  top: -2px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1633940255 svg {
  position: relative;
  display: block;
  width: calc(142% + 1.3px);
  height: 65px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1633940255 .shape-fill {
  fill: #ffffff;
}
.clickable:hover {
  cursor: pointer;
}

.phoneInput {
  color: white;
  border: none;
}
.responsiveImg {
  width: 100%;
  height: auto;
}
.responsivePicker {
  min-width: 200px;
  flex: 1;
}
.whiteIcon {
  color: white;
}

.selectedLink {
  color: #199e19;
  font-weight: 600;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.cursor {
  position: fixed;
  left: 0;
  top: 0;
  width: 128px;
  height: 92px;
  border-radius: 100%;
  pointer-events: none;
  background-image: url("assets/img/littleDragonAnimation.gif");
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 10;
}
.arrowDown {
  height: 54px;
  width: 54px;
}
.dragonBarrier {
  z-index: 15;
}
